/* eslint-disable @next/next/no-img-element */
"use client";
//? Library Imports ------------------------------------------------------------>
import axios from "axios";
import Image from "next/image";
import Link from "next/link";
import { debounce } from "lodash";
import _ from "lodash";
import { useEffect, useCallback, useRef, useState, Suspense } from "react";
import Cookies from "js-cookie";
import { Col, Row, Spin, Space, Typography, Button } from "antd";
import MariaBImage from "../../../assets/images/brands/mariaB.svg";
import manara from "../../../assets/images/brands/manara.svg";
import maryam from "../../../assets/images/brands/maryam.svg";
import mohagni from "../../../assets/images/brands/mohagni.svg";
import mashq from "../../../assets/images/brands/mashq.svg";

//? Assets --------------------------------------------------------------------->
import {
  SearchOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./MegaSearch.scss";

//? Modules -------------------------------------------------------------------->
import Loader from "../Loader/Loader";
import ProductCard from "../ProductsCarousel/ProductCard/ProductCard";
import SearchForMobile from "../../../components/shared/Search/MobileSearch/MobileSearchh";
import { GiMechanicGarage } from "react-icons/gi";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MegaSearchCustom({
  setLoading,
  userAgent,
  ActivePage,
  setSearchQuery,
  setcategorySearchFlag,
  SearchQuery,
  setActivePage,
  SearchResults,
  ShowMegaSearch,
  setShowMegaSearch,
  SearchedProducts,
  setSearchedProducts,
  setOnlyChangeProductsFlag,
  mainLoading,
  productLoader,
  loadMoreButtonFlag,
  setLoadMoreButtonFlag,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const SearchRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const { isMobile, isTablet, isDesktop } = userAgent;
  const [showMobileSearchBar, setShowMobileSearchBar] = useState("block");

  const [loadMoreButtonLoading, setLoadMoreButtonLoading] = useState(false);
  let landing_from = localStorage.getItem("collection_name")
  useEffect(() => {
    if (ShowMegaSearch) {
      setTimeout(() => {
        localStorage.removeItem("collection_name")
      }, [500])
    }
  })

  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const handleClickOutside = useCallback(
    (event) => {
      if (SearchRef.current && !SearchRef.current.contains(event.target)) {
        setShowMegaSearch(false);
        document.body.classList.remove("disableScroll");
      }
    },
    [setShowMegaSearch]
  );
  const handleKeyPress = (event) => {
    if (event.key === "Escape" || event.key === "Esc") {
      setShowMegaSearch(false);
    }
  };

  const delayedSearchQuery = debounce((query) => {
    setOnlyChangeProductsFlag(true);
    setActivePage(1);
    setcategorySearchFlag(true);
    setSearchQuery(query);
  }, 2000);

  const searchMoreProducts = useCallback(() => {
    setLoadMoreButtonLoading(true);
    const pageNo = ActivePage + 1;
    setActivePage(pageNo);

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
        `/storefront/search_products?page=${pageNo}&q=` +
        SearchQuery +
        "&limit=18" +
        `&storefront=${defaultCurrencyIdFromCookies}`
      )
      .then((response) => {
        if (response?.data?.results?.length > 17) {
          setLoadMoreButtonFlag(true);
        } else {
          setLoadMoreButtonFlag(false);
        }
        setSearchedProducts((prevProducts) => {
          const uniqueProducts = new Set([
            ...prevProducts,
            ...(response?.data?.results || []),
          ]);

          return [...uniqueProducts];
        });

        setTimeout(() => {
          setLoading(false);
          setLoadMoreButtonLoading(false);
        }, 1500);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
        setLoadMoreButtonLoading(false);
        setLoading(false);
      });
  }, [ActivePage, SearchQuery, setActivePage, setLoading, setSearchedProducts]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (ShowMegaSearch) {
      document.body.classList.add("search-opened");
      document.body.classList.add("disableScroll");
    } else {
      document.body.classList.remove("search-opened");
      document.body.classList.remove("disableScroll");
    }
    return () => {
      document.body.classList.remove("disableScroll");
    };
  }, [ShowMegaSearch]);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef?.current;

    const handleScroll = () => {
      const scrollPosition = scrollableDiv?.scrollTop;

      const maxScrollPosition =
        scrollableDiv?.scrollHeight - scrollableDiv?.clientHeight;

      if (
        scrollPosition + 20 >= maxScrollPosition &&
        SearchedProducts?.length < SearchResults?.count
      ) {
      }
      setLoading;
    };

    scrollableDiv?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [
    setLoading,
    SearchResults?.count,
    SearchedProducts?.length,
    searchMoreProducts,
  ]);

  return (
    ShowMegaSearch && (
      <Col
        span={24}
        ref={SearchRef}
        className="paddingLeft10 paddingRight10 positionRelative"
      >
        <div className="overlay"></div>

        {isMobile || isTablet ? (
          <Row className="MegaSearchCustom">
            <Col flex="100%" style={{ overflowY: "scroll", height: "100vh" }}>
              <div>
                <SearchForMobile
                  userAgent={{ isMobile, isTablet, isDesktop, userAgent }}
                  setShowMobileSearchBar={setShowMobileSearchBar}
                  setShowMegaSearch={setShowMegaSearch}
                />
              </div>
            </Col>
          </Row>
        ) : isDesktop && !mainLoading ? (
          <Row Row className="MegaSearchCustom">
            <Col
              flex="99%"
              className="borderLeft searchProducts positionRelative paddingRight15"
            >
              {SearchedProducts?.length ? (
                <Row gutter={[0, 12]} className="positionRelative row">
                  <Col span={24}>
                    <Space className="flexBetween width100 backgroundLight borderBottom padding15 paddingBottom10 paddingLeft0 marginLeft15 ">
                      <Text className="font14Bold"> Products </Text>

                      <CloseCircleOutlined
                        className="closeIcon"
                        style={{
                          color: "white",
                          fontSize: "30px",
                          cursor: "pointer",
                          background: mainColor,
                          borderRadius: "25px",
                        }}
                        onClick={() => {
                          setShowMegaSearch(false);
                          localStorage.setItem("collection_name", landing_from);
                        }}
                      />
                    </Space>
                  </Col>

                  <Col
                    span={24}
                    ref={scrollableDivRef}
                    className="paddingLeft15 paddingRight15 productsListing scroll marginBottom20"
                  >
                    {!productLoader ? (
                      <Row gutter={[10, 15]} className="positionRelative">
                        <div
                          style={{
                            height: "100%",
                            maxHeight: "100%",
                            width: "100%",
                          }}
                        >
                          <Row
                            gutter={[10, 15]}
                            className="positionRelative product-grid"
                          >
                            {SearchedProducts?.map((product, index) => {
                              return (
                                <Col
                                  xxl={4}
                                  xl={6}
                                  lg={6}
                                  md={4}
                                  sm={6}
                                  key={index}
                                  className="padding0"
                                >
                                  <Link
                                    key={index}
                                    href={product?.handle}
                                    onClick={() => setShowMegaSearch(false)}
                                  >
                                    <ProductCard
                                      product={product}
                                      userAgent={userAgent}
                                      megaSearchMenuCheck
                                    />
                                  </Link>
                                </Col>
                              );
                            })}

                            {loadMoreButtonLoading ? (
                              <Col span={24} className="flexCenter">
                                <Loader />
                              </Col>
                            ) : (
                              loadMoreButtonFlag && (
                                <Col sm={24} className="flexCenter padding20">
                                  <Button
                                    type="primary"
                                    className="search-load-more-button"
                                    outline
                                    onClick={() => {
                                      searchMoreProducts();
                                    }}
                                  >
                                    Load More
                                  </Button>
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      </Row>
                    ) : (
                      <Col sm={24} className="flexCenter">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 50,
                                color: "#ff000",
                              }}
                              spin
                            />
                          }
                        />
                      </Col>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row
                  gutter={[0, 12]}
                  className="positionRelative row paddingLeft15"
                >
                  <Col span={24}>
                    <Space className="notFoundSpace flexBetween width100 backgroundLight borderBottom padding15 paddingBottom10 paddingLeft0 ">
                      <Space
                        className="width100"
                        align="center"
                        style={{
                          width: "100%",
                          padding: "5px",
                          background: "#F8D7DA",
                        }}
                      >
                        <ExclamationCircleOutlined className="ExclamationCircle" />

                        <Text className="font14 width100">
                          Sorry, No Result Found
                        </Text>
                      </Space>

                      <CloseCircleOutlined
                        className="closeIcon marginRight10"
                        style={{
                          color: "white",
                          fontSize: "22px",
                          cursor: "pointer",
                          background: "black",
                          borderRadius: "25px",
                        }}
                        onClick={() => setShowMegaSearch(false)}
                      />
                    </Space>
                  </Col>

                  <Col span={24} className="productsListing">
                    {SearchResults?.featured_collections?.length ? (
                      <>
                        <Col
                          span={24}
                          style={{ width: "77%" }}
                          className="backgroundLight borderBottom padding15 paddingLeft0"
                        >
                          <Text className="font14Bold">
                            Featured Collection
                          </Text>
                        </Col>

                        <Col span={24} className="paddingLeft15 paddingRight15">
                          <Row gutter={[10, 15]} className="positionRelative">
                            {SearchResults?.featured_collections?.map(
                              (collection, index) => {
                                return (
                                  <Col
                                    key={index}
                                    flex="14.2%"
                                    className="padding0"
                                  >
                                    <Link
                                      href={collection?.link}
                                      onClick={() => setShowMegaSearch(false)}
                                    >
                                      <Space
                                        direction="vertical"
                                        align="center"
                                      >
                                        <img
                                          width={112}
                                          height={111}
                                          src={collection.img}
                                          alt={
                                            collection?.alt_text
                                              ? collection?.alt_text
                                              : "Collection"
                                          }
                                        />
                                        <Text className="font18">
                                          {collection?.title
                                            ? collection?.title
                                            : "N/A"}
                                        </Text>
                                      </Space>
                                    </Link>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </Col>
                      </>
                    ) : null}

                    {SearchResults?.catalogue_lists?.length ? (
                      <>
                        <Col
                          span={24}
                          style={{ width: "77%", marginTop: "0px" }}
                          className="backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                        >
                          <Text className="font14Bold">New Catalog</Text>
                        </Col>

                        <Col span={24} className="paddingLeft15 paddingRight15">
                          <Row gutter={[10, 15]} className="positionRelative">
                            {SearchResults?.catalogue_lists?.map(
                              (catalogue, index) => {
                                return (
                                  <Col
                                    span={4}
                                    className="padding0"
                                    key={index}
                                  >
                                    <Link
                                      href={catalogue?.collection_handle}
                                      onClick={() => setShowMegaSearch(false)}
                                    >
                                      <img
                                        width={163}
                                        height={180}
                                        alt="Catalogue"
                                        src={catalogue?.cdn_link}
                                      />
                                    </Link>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </Col>
                      </>
                    ) : null}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        ) : (
          <Row
            Row
            className="MegaSearchCustom spinner-flex-justify-content-center"
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 50,
                    color: "#FE4",
                  }}
                  spin
                />
              }
            />
          </Row>
        )}
      </Col>
    )
  );
}
