"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Col,
  Row,
  Tag,
  Spin,
  Space,
  message,
  Divider,
  Dropdown,
  Typography,
  // Image,
  // Badge,
} from "antd";
import Image from "next/image";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";

//? Assets --------------------------------------------------------------------->
import "./WishlistDrop.scss";
import { AiOutlineHeart, AiFillCloseCircle } from "react-icons/ai";
import EmptyWishlist from "../../../../../../assets/images/CartPage/empty-cart.png";
import DynamicButton from "@/components/shared/Buttons/DynamicButton/DynamicButton";
import AccountStatus from "@/functions/Cookies/Cookies";
import { displayWishlist } from "@/redux/Slices/wishListSlice";

//------------------------------------------------------------------------------->
import { changeActiveMenuReducer } from "@/redux/Slices/accountSlice";
import { removeCommasAndConvertToNumber } from "@/functions/HelperFunctions";
import wishlistIcon from "../../../../../../assets/Icons/WishlistIcon.svg";
import defaultImage from "../../../../../../assets/images/defaultImages/alfa-loader.gif";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;
// const defaultImage = "https://rb.gy/wi9shp";

export default function WishlistDrop() {
  const {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();
  const [selectedIcon, setSelectedIcon] = useState(null);
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const defaultCurrency = defaultCurrencyFromCookies;
  const showWishlistDropdown = useSelector(
    (state) => state?.wishListSlice?.showWishlist
  );
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  //? Cart Module States --------------------------------------------------------->
  const [isOpen, setIsOpen] = useState(null);
  const [WishlistData, setWishlistData] = useState();

  const [Loading, setLoading] = useState(false);
  let storeId = defaultCurrencyIdFromCookies ?? "23";
  //? Functions ------------------------------------------------------------------>
  const fetchWishlistProducts = useCallback(async () => {
    if (!loggedIn) {
      return null;
    } else
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
        };
        if (comverse_customer_token) {
          headers.Authorization = comverse_customer_token;
        }

        const payload = {
          method: "GET",
          headers: headers,
        };

        const WishlistData = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
            `/storefront/wishlist_list?storefront=${defaultCurrencyIdFromCookies}`,
          payload
        );

        if (!WishlistData.ok) {
          throw new Error("ViewCart API Failed!");
        }

        const Products = await WishlistData.json();

        if (Products) {
          setWishlistData(Products);
          setLoading(false);
        }

        return Products;
      } catch (error) {
        // message.error("Wishlist API Failed!");
        console.error("Error fetching data:", error.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return null;
      }
  }, [comverse_customer_token, loggedIn]);

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        dispatch(displayWishlist(false));
      }
    },
    [dispatch]
  );

  //? Life Cycles ---------------------------------------------------------------->
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll", "overlay-container");
    } else {
      document.body.classList.remove("no-scroll", "overlay-container");
    }

    return () => {
      document.body.classList.remove("no-scroll", "overlay-container");
    };
  }, [isOpen]);

  useEffect(() => {
    if (loggedIn && isOpen) {
      fetchWishlistProducts();
    }
  }, [loggedIn, isOpen, fetchWishlistProducts]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    if (dropdownRef.current) {
      dropdownRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  });

  // }, [dispatch, dropdownRef.current]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("dropdown-opened");
    } else {
      document.body.classList.remove("dropdown-opened");
    }
  }, [isOpen]);

  useEffect(() => {
    if (showWishlistDropdown) {
      setIsOpen(true);
    }
  }, [showWishlistDropdown]);

  return (
    <>
      <Col span={24} className="WishlistDropdown">
        <Col
          onMouseOver={() => setSelectedIcon(3)}
          onMouseOut={() => setSelectedIcon(null)}
          onClick={() => setIsOpen(!isOpen)}
          className="flexColumn flexCenter cursorPointer"
        >
          <Text
            className={
              selectedIcon === 3
                ? "icon-container icon-container-active"
                : "icon-container"
            }
          >
            <Image height={17} width="auto" alt="wishlist" src={wishlistIcon} />
          </Text>
        </Col>

        {/* {isOpen && <div className="overlay"></div>} */}
        {isOpen && (
          <Col
            ref={dropdownRef}
            className="WishlistDropdownContent"
            style={{
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
            onClick={() => {
              dispatch(displayWishlist(false));
              setIsOpen(false);
            }}
          >
            <Text className="font18Bold marginBottom15">Wishlist</Text>
            <Spin spinning={Loading}>
              {loggedIn && WishlistData?.results?.length ? (
                <Row className="marginTop15" justify="center">
                  <Col span={24} className="WishlistItems">
                    {WishlistData?.results?.map((wishlistItem, index) => {
                      if (!wishlistItem?.sold_out) {
                        const productVariant = wishlistItem?.variants?.find(
                          (variant) =>
                            variant?.variant_detail?.sold_out === false
                        );
                        const discountPercentage = () => {
                          const originalPrice = removeCommasAndConvertToNumber(
                            productVariant?.variant_detail?.original_price
                          );
                          const discountedPrice =
                            removeCommasAndConvertToNumber(
                              productVariant?.variant_detail?.discounted_price
                            );

                          if (
                            originalPrice &&
                            discountedPrice &&
                            originalPrice !== 0
                          ) {
                            const percentageOff = Math.round(
                              ((originalPrice - discountedPrice) /
                                originalPrice) *
                                100
                            );
                            return `${percentageOff}% OFF`;
                          }

                          return "";
                        };

                        if (productVariant) {
                          return (
                            <Col
                              span={24}
                              key={index}
                              className="padding10 borderBottom"
                            >
                              <Space
                                className="both"
                                style={{
                                  width: "100%",
                                  // padding: "10px",
                                  alignItems: "flex-start",
                                  // justifyContent: "space-between",
                                }}
                              >
                                <Image
                                  width={72}
                                  height={95}
                                  // preview={false}
                                  alt="Wishlist Product"
                                  // src={wishlistItem?.images[0]?.cdn_link}
                                  src={
                                    wishlistItem?.images[0]?.cdn_link
                                      ? wishlistItem?.images[0]?.cdn_link
                                      : defaultImage
                                  }
                                />
                                <Space
                                  className="gap10"
                                  direction="vertical"
                                  style={{ width: "100%" }}
                                >
                                  <Text className="name font15Bold ">
                                    {wishlistItem?.title
                                      ? wishlistItem?.title
                                      : "N/A"}
                                  </Text>

                                  <Col flex="1 1 auto">
                                    {removeCommasAndConvertToNumber(
                                      productVariant?.variant_detail
                                        ?.discounted_price
                                    ) <=
                                    removeCommasAndConvertToNumber(
                                      productVariant?.variant_detail
                                        ?.original_price
                                    ) ? (
                                      <Row
                                        style={{ overflow: "hidden" }}
                                        gutter={[10, 0]}
                                      >
                                        <Col>
                                          <Text className="font14Bold">
                                            {defaultCurrency}
                                            &nbsp;
                                            {/* {productVariant?.variant_detail
                                              ?.discounted_price
                                              ? parseFloat(
                                                  productVariant?.variant_detail
                                                    ?.discounted_price
                                                ).toFixed(2)
                                              : "N/A"} */}
                                            {
                                              productVariant?.variant_detail
                                                ?.discounted_price
                                            }
                                          </Text>
                                        </Col>

                                        {removeCommasAndConvertToNumber(
                                          productVariant?.variant_detail
                                            ?.discounted_price
                                        ) <
                                        removeCommasAndConvertToNumber(
                                          productVariant?.variant_detail
                                            ?.original_price
                                        ) ? (
                                          <Space className="paddingLeft5">
                                            <Text className="font16 priceCut poppinsLight">
                                              {defaultCurrency}
                                              &nbsp;
                                              {/* {productVariant?.variant_detail
                                                ?.original_price
                                                ? parseFloat(
                                                  productVariant
                                                    ?.variant_detail
                                                    ?.original_price
                                                ).toFixed(2)
                                                : "N/A"} */}
                                              {
                                                productVariant?.variant_detail
                                                  ?.original_price
                                              }
                                            </Text>
                                            {/* <Tag className="discountTag alignCenter borderNone borderRadius0 font16">
                                              {discountPercentage()}
                                            </Tag> */}
                                          </Space>
                                        ) : null}
                                      </Row>
                                    ) : null}
                                  </Col>
                                  <Text className="font14">
                                    {`SKU: `}
                                    {productVariant?.sku
                                      ? productVariant?.sku
                                      : "N/A"}
                                  </Text>
                                </Space>
                              </Space>
                            </Col>
                          );
                        } else {
                          // Out of stock view for wishlist items
                        }
                      }
                    })}
                  </Col>

                  <Col span={24} className="flexCenter">
                    <DynamicButton
                      name="View Wishlist"
                      link="/account"
                      function={() => {
                        dispatch(changeActiveMenuReducer("wishlist"));
                      }}
                      style={{
                        height: "49px",
                        width: "370px",
                        fontWeight: 600,
                        color: mainColor,
                        fontSize: "16px",
                        borderRadius: "6px",
                        // margin: "30px auto",
                        border: `1px solid ${mainColor}`,
                        margin: "20px auto 20px auto",
                      }}
                    />
                  </Col>

                  <Col span={24} className="marginTop10 flexCenter">
                    <AiFillCloseCircle style={{ fontSize: "24px" }} />
                  </Col>
                </Row>
              ) : (
                <Col className="flexColumn flexCenter">
                  <Image
                    width={150}
                    height={150}
                    src={EmptyWishlist}
                    alt="Empty Wishlist"
                    // preview={false}
                  />

                  <Text
                    style={{
                      opacity: 1,
                      fontSize: "20px",
                      color: "#000000",
                      letterSpacing: "0px",
                      fontFamily: "ZemestroStd-Medium",
                      fontWeight: 100,
                    }}
                  >
                    {`Hey! It's lonely here`}
                  </Text>
                  <Text
                    style={{
                      opacity: 1,
                      fontWeight: 100,
                      color: "#747474",
                      letterSpacing: "0px",
                      fontFamily: "ZemestroStd-Regular",
                    }}
                  >
                    Your Wishlist is empty
                  </Text>

                  <DynamicButton
                    link="/"
                    name="Shop Now"
                    className="primary_btn_outline font18Bold"
                    style={{
                      width: "171px",
                      height: "41px",
                      margin: "30px auto 20px auto",
                    }}
                  />
                </Col>
              )}
            </Spin>
          </Col>
        )}
      </Col>
    </>
  );
}
