"use client";
//? Library Imports ------------------------------------------------------------>
import axios from "axios";
import Link from "next/link";
import debounce from "lodash/debounce";
import Loader from "../../Loader/Loader";
import { Spin, Button } from "antd";
import { useCallback, useState, useEffect, useRef } from "react";
import { Col, Row, Input, Space, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import "./MobileSearch.scss";
import { BsSearch } from "react-icons/bs";
import {
  LeftOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
//? Modules -------------------------------------------------------------------->
import DynamicButton from "../../Buttons/DynamicButton/DynamicButton";
import ProductCard from "../../ProductsCarousel/ProductCard/ProductCard";
import Cookies from "js-cookie";
import { getCurrentDateTime } from "@/functions/HelperFunctions/index";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MobileSearchh({
  userAgent,
  setShowMegaSearch,
  setDisplayMobileSearchMenu,
}) {
  const { isMobile, isTablet, isDesktop } = userAgent;
  const mobileSearchRef = useRef(null); // Ref for the component

  //? States --------------------------------------------------------------------->
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const [ActivePage, setActivePage] = useState(1);
  const [SearchQuery, setSearchQuery] = useState("");
  const [ActiveTab, setActiveTab] = useState("products");
  const [SearchResults, setSearchResults] = useState({});
  const [SearchedProducts, setSearchedProducts] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loadMoreButtonLoading, setLoadMoreButtonLoading] = useState(false);
  const [loadMoreButtonFlag, setLoadMoreButtonFlag] = useState(false);
  const [brandArray, setBrandArray] = useState([]);

  //? Functions ------------------------------------------------------------------>

  const moengageEventForSearch = (searchedString) => {
    let currentData = getCurrentDateTime();
    if (window.Moengage && window.Moengage.track_event) {
      window.Moengage.track_event("Product Searched", {
        "Event Received Time": currentData,
        Currency: defaultCurrencyFromCookies
          ? defaultCurrencyFromCookies
          : "PKR",
        "Search String": searchedString,
        Source: "Website",
      });
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    moengageEventForSearch(value);
    setSearchQuery(value);
    searchResult(value);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("q", value);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const searchResult = (query) => {
    setSearchLoading(true);

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/search_products?page=${ActivePage}&q=` +
          query +
          "&limit=18" +
          `&storefront=${defaultCurrencyIdFromCookies}`
      )
      .then((response) => {
        setActiveTab("products");
        setActivePage(ActivePage + 1);
        setBrandArray(response?.data?.brand_aggs);
        setSearchResults(response?.data);
        setSearchedProducts(response?.data?.results);
        if (response?.data?.results?.length > 17) {
          setLoadMoreButtonFlag(true);
        } else {
          setLoadMoreButtonFlag(false);
        }
        setSearchLoading(false);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
        setSearchLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchQuery = useCallback(debounce(handleSearch, 1000), []);

  const loadMoreData = useCallback(() => {
    setLoadMoreButtonLoading(true);

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/search_products?page=${ActivePage}&q=` +
          SearchQuery +
          "&limit=18" +
          `&storefront=${defaultCurrencyIdFromCookies}`
      )
      .then((response) => {
        setSearchedProducts((prevProducts) => {
          setActivePage(ActivePage + 1);
          if (response?.data?.results?.length > 17) {
            setLoadMoreButtonFlag(true);
          } else {
            setLoadMoreButtonFlag(false);
          }
          const uniqueProducts = new Set([
            ...prevProducts,
            ...(response?.data?.results || []),
          ]);

          return [...uniqueProducts];
        });

        setTimeout(() => {
          setLoadMoreButtonLoading(false);
        }, 1500);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
        setLoadMoreButtonLoading(false);
      });
  }, [ActivePage, SearchQuery, setActivePage, setSearchedProducts]);

  useEffect(() => {
    if (SearchQuery === "" && !SearchedProducts?.length) searchResult("");
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileSearchRef.current &&
        !mobileSearchRef.current.contains(event.target)
      ) {
        setDisplayMobileSearchMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDisplayMobileSearchMenu]);

  return (
    <Col className="MobileSearch" ref={mobileSearchRef}>
      <Col span={24} className="backgroundLight marginBottom50">
        <Row className="searchBar width100">
          <Col flex="10%" className="flexCenter">
            <div
              onClick={() => {
                setShowMegaSearch && setShowMegaSearch(false);
                setDisplayMobileSearchMenu && setDisplayMobileSearchMenu(false);
              }}
            >
              <LeftOutlined className="cursorPointer" />
            </div>
          </Col>

          <Col flex="85%" className="flexCenter">
            <Input
              autoFocus={true}
              size="large"
              placeholder="What are you looking for?"
              className="searchInput"
              suffix={<BsSearch />}
              onChange={searchQuery}
              onPressEnter={searchQuery}
            />
          </Col>
        </Row>
      </Col>

      <div className="search-menu-brand-list-mobile">
        {brandArray?.map((item, index) => {
          if (
            index < 12 &&
            item?.logo_image !== "" &&
            item?.logo_image !== null &&
            item?.logo_image
          ) {
            return (
              <Link href={item?.handle} key={index}>
                <div key={index}>
                  <div
                    className="search-menu-single-brand-mobile-div"
                    key={index}
                  >
                    <img
                      src={item?.logo_image}
                      alt="brand logo"
                      className="search-menu-single-brand-logo-image"
                    />
                  </div>
                </div>
              </Link>
            );
          }
        })}
      </div>

      {ActiveTab === "products" ? (
        <Col span={24} className="marginBottom50">
          {!searchLoading ? (
            <Col span={24} className="mobile-search-product-container">
              {SearchedProducts?.length ? (
                <Row gutter={[0, 12]} className="positionRelative row">
                  <Col span={24} className="">
                    <Text className="font14Bold">Products</Text>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[10, 15]} className="positionRelative">
                      {SearchedProducts?.map((product, index) => {
                        return (
                          <Col xs={12} key={index} className="padding0">
                            <ProductCard
                              product={product}
                              userAgent={userAgent}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Col span={24}>
                  <Space
                    className="width100"
                    align="center"
                    style={{
                      width: "100%",
                      padding: "5px",
                      background: "#F8D7DA",
                    }}
                  >
                    <ExclamationCircleOutlined className="ExclamationCircle" />

                    <Text className="font14 width100">
                      Sorry, No Result Found
                    </Text>
                  </Space>
                </Col>
              )}
            </Col>
          ) : (
            <Row className="mobile-header-spinner-div">
              <Col span={24} align="center">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 40,
                      }}
                      spin
                    />
                  }
                />
              </Col>
            </Row>
          )}
          {loadMoreButtonLoading ? (
            <Col span={24} className="flexCenter paddingBottom50">
              <Loader />
            </Col>
          ) : (
            !searchLoading &&
            SearchedProducts?.length > 17 &&
            loadMoreButtonFlag && (
              <Col sm={24} className="flexCenter paddingTop30 paddingBottom30">
                <Button
                  type="primary"
                  className="search-load-more-button-mobile"
                  outline
                  onClick={() => {
                    loadMoreData();
                  }}
                >
                  Load More
                </Button>
              </Col>
            )
          )}
        </Col>
      ) : (
        <Col span={24} className="padding15 marginBottom50">
          {SearchResults?.collection_aggs?.length > 0 ? (
            <Col span={24}>
              <Col span={24} className="marginBottom10">
                <Text className="font12">Related Categories</Text>
              </Col>

              <Row gutter={[10, 15]} className="mobile-collection-lists">
                {SearchResults?.collection_aggs?.map((brand, index) => {
                  return (
                    <Link href={`/${brand?.handle}`} key={index}>
                      <Col flex="auto">
                        <Text key={index} className="tag font12">
                          {brand?.name}
                        </Text>
                      </Col>
                    </Link>
                  );
                })}
              </Row>
            </Col>
          ) : null}
        </Col>
      )}

      <Col span={24}>
        {SearchedProducts?.length ? (
          <Col
            className="backgroundLight paddingTop5 paddingBottom5"
            style={{
              left: "0px",
              right: "0px",
              zIndex: "5",
              bottom: "0px",
              position: "fixed",
            }}
          >
            <Row
              className={
                isMobile || isTablet
                  ? "width100 flexBetween paddingLeft10 paddingRight10 marginBottom5 marginTop5"
                  : isDesktop
                  ? "width100 flexBetween marginTop10 marginBottom10"
                  : ""
              }
            >
              <Col flex="49%">
                <DynamicButton
                  type="primary"
                  name="Products"
                  className={
                    ActiveTab === "products"
                      ? "primary_btn poppinsMedium"
                      : "primary_btn_outline poppinsMedium"
                  }
                  function={() => setActiveTab("products")}
                  style={{
                    height: "40px",
                    fontSize: "12px",
                    borderRadius: "3px",
                    textTransform: "capitalize",
                  }}
                />
              </Col>

              <Col flex="49%">
                <DynamicButton
                  type="primary"
                  name="Related Categories"
                  className={
                    ActiveTab === "categories"
                      ? "primary_btn poppinsMedium"
                      : "primary_btn_outline poppinsMedium"
                  }
                  function={() => setActiveTab("categories")}
                  style={{
                    height: "40px",
                    fontSize: "12px",
                    borderRadius: "3px",
                    textTransform: "capitalize",
                  }}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Col>
    </Col>
  );
}
