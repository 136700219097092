"use client";

import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { Col, Drawer, Row, Space, Menu, Input } from "antd";
import { debounce } from "lodash";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import Link from "next/link";
import "./SideNavDrawer.scss";
import CustomScrollbar from "@/components/CustomScrollBar/CustomScrollBar";
import Cookies from "js-cookie";

const { Search } = Input;

export default function SideNavDrawer({
  data,
  userAgent,
  showSideNav,
  setShowSideNav,
}) {
  const router = useRouter();
  const { isMobile } = userAgent;

  const [items, setItems] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [openedParent, setOpenedParent] = useState(null);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const displayMenuItems = (itemIndex) => {
    if (openedParent === itemIndex) {
      setOpenedParent(null);
      setOpenKeys([]);
    } else {
      setOpenedParent(itemIndex);
      setOpenKeys([`cat${itemIndex}`]);
    }
  };

  const onCloseSideNav = () => {
    setShowSideNav(false);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const onSearchBrands = (value) => {
    const brandsData = data?.find((item) => item?.name === "Brands")?.children;
    const results = brandsData?.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    const brandMenuItems =
      results?.map((item, index) => ({
        key: `sub${index}`,
        label: item.name,
      })) || [];

    setItems(brandMenuItems);
  };

  const debouncedSearchBrands = debounce(onSearchBrands, 300);

  const renderMenuItems = (items, level = 0) => {
    return items.map((item, index) => {
      const hasChildren = item?.children;

      return getItem(
        renderLabelWithLink(item.name, hasChildren, item),
        `sub${level}_${index}`,
        null,
        hasChildren.length > 0
          ? renderMenuItems(item.children, level + 1)
          : null
      );
    });
  };

  const renderLabelWithLink = (label, children, item) => {
    return children?.length > 0 ? (
      <span>{label}</span>
    ) : (
      <Link onClick={() => onCloseSideNav()} href={"/" + item?.handle || "#"}>
        {label}
      </Link>
    );
  };

  const SideNavContent = (
    <div className="side-nav" style={{ overflowY: "auto" }}>
      <CustomScrollbar mainColor={mainColor} targetClass="side-nav" width={2} />
      {data?.map((item, itemIndex) => (
        <div key={itemIndex}>
          <Row
            align="middle"
            justify="space-between"
            className="menu-item"
            onClick={() => displayMenuItems(itemIndex)}
          >
            <Col
              flex="auto"
              className={`side-menu-container ${
                item.children?.length === 0 ? "no-children" : ""
              }`}
            >
              <Space className="side-menu">
                {renderLabelWithLink(item.name, item.children, item)}
                {item.children?.length > 0 && (
                  <Col className="arrows">
                    {openedParent === itemIndex ? (
                      <MdOutlineKeyboardArrowUp />
                    ) : (
                      <MdOutlineKeyboardArrowDown />
                    )}
                  </Col>
                )}
              </Space>
            </Col>
          </Row>
          {openedParent === itemIndex && item.children && (
            <div className="sub-menu">
              <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                onSelect={({ key }) => {
                  setOpenedParent(null);
                  onCloseSideNav();
                }}
                items={renderMenuItems(item.children)}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return isMobile ? (
    <Drawer
      placement="left"
      open={showSideNav}
      onClose={onCloseSideNav}
      width="100%"
      rootClassName="mobile-side-nav-drawer"
    >
      {SideNavContent}
    </Drawer>
  ) : (
    SideNavContent
  );
}
