"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Col,
  Row,
  Tag,
  Spin,
  Space,
  message,
  Divider,
  Dropdown,
  Typography,
  // Image,
  // Badge,
} from "antd";
import Image from "next/image";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import CartIcon from "../../../../assets/Icons/CartIcon.svg";
import EmptyBag from "../../../../assets/images/CartPage/empty-cart.png";
//? Assets --------------------------------------------------------------------->
import "./CartDropp.scss";
// import "./CartDropdown.scss";
import { BsHandbag } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import ArrowDown from "../../../../assets/images/CartPage/ArrowDown.svg";
import EmptyCart from "../../../../assets/images/CartPage/EmptyCart.png";

//? Modules -------------------------------------------------------------------->
import DynamicButton from "@/components/Shared/Buttons/DynamicButton/DynamicButton";

//? Redux Reducers ------------------------------------------------------------->
import {
  displayCart,
  updateCartCount,
  clearCart,
  updateCart,
} from "@/redux/Slices/cartSlice";
import {
  // setCheckoutId,
  clearCheckout,
  setUserInformation,
} from "@/redux/Slices/checkoutSlice";
import defaultImage from "../../../../assets/images/defaultImages/alfa-loader.gif";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;
// const defaultImage = "https://rb.gy/wi9shp";

export default function CartDropp() {
  //? Hooks ---------------------------------------------------------------------->
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  //? Redux States --------------------------------------------------------------->
  let cartId = Cookies.get("cartId");
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  const defaultCurrency = defaultCurrencyFromCookies;
  const showCartDropdown = useSelector((state) => state?.cartSlice?.showCart);

  //? Cart Module States --------------------------------------------------------->
  const [isOpen, setIsOpen] = useState(false);
  const [CartData, setCartData] = useState();
  const [Loading, setLoading] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  //? Functions ------------------------------------------------------------------>
  const clearCartData = () => {
    //below is commented ------------
    // dispatch(clearCart());
    //-------------------------------
    // dispatch(setCheckoutId(null));
    // <Navigate to="/" replace={true} />;

    //---------method I added------
    dispatch(clearCheckout());
    dispatch(clearCart());
    dispatch(updateCart());
    dispatch(updateCartCount(0));
    Cookies.remove("cartId");
    cartId = Cookies.get("cartId");
  };
  const fetchCartProducts = useCallback(async () => {
    //
    if (!cartId) {
      dispatch(updateCartCount(0));
      setLoading(false);
      return;
    } else
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
        };
        if (cartId) {
          headers.cartid = cartId;
        }

        const payload = {
          method: "GET",
          headers: headers,
        };
        //

        const CartData = await fetch(
          process.env.NEXT_PUBLIC_BACKEND +
          `/order/view_cart?storefront=23`,
          payload
        );

        const Products = await CartData.json();

        //
        if (!CartData.ok) {
          if (Products.detail === "cart not found") {
            clearCartData();
          }
          throw new Error("ViewCart API Failed!");
        } else {
          //
          setCartData(Products);
          setLoading(false);
          return Products;
        }
      } catch (error) {
        //
        // message.error("ViewCart API Failed!");
        console.error("Error fetching data:", error.message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return null;
      }
  }, [cartId]);

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        dispatch(displayCart(false));
      }
    },
    [dispatch]
  );

  //? Life Cycles ---------------------------------------------------------------->
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll", "overlay-container");
    } else {
      document.body.classList.remove("no-scroll", "overlay-container");
    }

    return () => {
      document.body.classList.remove("no-scroll", "overlay-container");
    };
  }, [isOpen]);

  useEffect(() => {
    const handleMouseLeave = () => {
      setIsOpen(false);
    };
    if (dropdownRef.current) {
      dropdownRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  });

  useEffect(() => {
    if (cartId && isOpen) {
      fetchCartProducts();
    }
  }, [cartId, isOpen, fetchCartProducts]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("dropdown-opened");
    } else {
      document.body.classList.remove("dropdown-opened");
    }
  }, [isOpen]);

  useEffect(() => {
    if (showCartDropdown) {
      setIsOpen(true);
    }
  }, [showCartDropdown]);

  return (
    <>
      <Col span={24} className="CartDropdown">
        <Col
          onMouseOver={() => setSelectedIcon(1)}
          onMouseOut={() => setSelectedIcon(null)}
          onClick={() => setIsOpen(!isOpen)}
          className="flexColumn flexCenter cursorPointer"
        >
          {/* <Text
            className={
              selectedIcon === 1
                ? "icon-container icon-container-active"
                : "icon-container"
            }
          > */}
          <Image height={22} width="auto" alt="wishlist" src={CartIcon} />
          {/* <BsHandbag style={{ color: isOpen ? "#FF0D50" : "" }} /> */}
          {/* <span className="name" style={{ color: isOpen ? "#FF0D50" : "" }}>
              Bag
            </span> */}
          {/* </Text> */}
        </Col>

        {/* {isOpen && <div className="overlay"></div>} */}
        {isOpen && (
          <Col
            ref={dropdownRef}
            className="CartDropdownContent"
            style={{
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          // onClick={() => setIsOpen(false)}
          >
            <Text className="font18Bold marginBottom15">Cart</Text>
            <Spin spinning={Loading}>
              {CartData?.line_items?.length ? (
                <Row className="marginTop15" justify="center">
                  <Col span={24} className="CartItems scroll">
                    {CartData?.line_items?.map((cartItem, index) => {
                      const selectedAddons = cartItem.add_on_templates.flatMap(
                        (template) =>
                          template.add_ons.flatMap((addon) =>
                            addon.options
                              .filter((option) =>
                                cartItem.selected_add_ons.some(
                                  (selectedAddon) =>
                                    selectedAddon.option === option.id
                                )
                              )
                              .map((option) => ({
                                ...addon,
                                selected_option: option,
                              }))
                          )
                      );
                      const size = selectedAddons.find(
                        (addOns) => addOns?.name.toLowerCase() === "size"
                      );

                      const selectedVariant = cartItem.variants.find(
                        (variant) =>
                          variant?.variant_detail?.variant_id ===
                          cartItem.variant_price_inventory
                      );
                      const getOptionTitle = (options, position) => {
                        const option = options.find(
                          (opt) => opt.position === position
                        );
                        return option ? option.name : "Size";
                      };
                      return (
                        <Col span={24} key={index} className="padding10">
                          <Space
                            className="both"
                            style={{
                              width: "100%",
                              // padding: "10px",
                              alignItems: "flex-start",
                              // justifyContent: "space-between",
                            }}
                          >
                            <Image
                              width={120}
                              height={160}
                              // preview={false}
                              alt="Cart Product"
                              // src={cartItem?.images[0]?.cdn_link}
                              src={
                                cartItem?.img_url
                                  ? cartItem?.img_url
                                  : defaultImage
                              }
                            />
                            <Space
                              className="gap10"
                              direction="vertical"
                              style={{ width: "100%" }}
                            >
                              <Text className="font14Bold">
                                SKU: {cartItem?.sku ? cartItem?.sku : "N/A"}
                              </Text>
                              <Text className="name font14">
                                {cartItem?.title ? cartItem?.title : "N/A"}
                              </Text>

                              <Space className="gap10">
                                {/* Show Size For Pret Product  */}

                                {!cartItem?.is_stitch &&
                                  !size &&
                                  !selectedVariant?.option1.includes(
                                    "Default"
                                  ) ? (
                                  <Space
                                    className="cursorPointer flexBetween"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      background: "#f2f3f7",
                                      maxWidth: "-webkit-fill-available",
                                    }}
                                  >

                                    {selectedVariant?.option1 && (
                                      <Text className="font14Bold">
                                        {getOptionTitle(cartItem.options, 1)}
                                        :&nbsp;
                                        <Text>
                                          {selectedVariant?.option1
                                            ? selectedVariant.option1
                                            : null}
                                        </Text>
                                      </Text>
                                    )}
                                  </Space>
                                ) : null}
                              </Space>
                              <Space className="gap10">
                                {selectedVariant?.option2 ? (
                                  <Space
                                    className="cursorPointer flexBetween"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      background: "#f2f3f7",
                                      maxWidth: "-webkit-fill-available",
                                    }}
                                  >
                                    <Text className="font14Bold">
                                      {getOptionTitle(cartItem.options, 2)}
                                      :&nbsp;
                                      <Text>{selectedVariant?.option2}</Text>
                                    </Text>
                                  </Space>
                                ) : null}
                              </Space>
                              <Space className="gap10">
                                {selectedVariant?.option3 ? (
                                  <Space
                                    className="cursorPointer flexBetween"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      background: "#f2f3f7",
                                      maxWidth: "-webkit-fill-available",
                                    }}
                                  >
                                    <Text className="font14Bold">
                                      {getOptionTitle(cartItem.options, 3)}
                                      :&nbsp;
                                      <Text>{selectedVariant?.option3}</Text>
                                    </Text>
                                  </Space>
                                ) : null}
                              </Space>
                              <Space
                                className="cursorPointer flexBetween"
                                style={{
                                  width: "60px",
                                  padding: "5px",
                                  background: "#f2f3f7",
                                  maxWidth: "-webkit-fill-available",
                                }}
                              >
                                <Text className="font14Bold">
                                  Qty:&nbsp;
                                  <span className="font14">
                                    {cartItem?.quantity
                                      ? cartItem?.quantity
                                      : "N/A"}
                                  </span>
                                </Text>
                              </Space>
                              <Space className="gap10">
                                {cartItem?.installment_plan ? (
                                  <Space
                                    className="cursorPointer flexBetween"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      background: "#f2f3f7",
                                      maxWidth: "-webkit-fill-available",
                                    }}
                                  >
                                    <Text className="font14Bold">
                                      Plan:&nbsp; {cartItem?.installment_plan}
                                    </Text>
                                  </Space>
                                ) : (
                                  <Space
                                    className="cursorPointer flexBetween"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      background: "#f2f3f7",
                                      maxWidth: "-webkit-fill-available",
                                    }}
                                  >
                                    <Text className="font14Bold">
                                      Plan:&nbsp; Pay In Full
                                    </Text>
                                  </Space>
                                )}
                              </Space>
                              {cartItem?.card_type ? (
                                <Space
                                  className="cursorPointer flexBetween"
                                  style={{
                                    width: "150px",
                                    padding: "5px",
                                    background: "#f2f3f7",
                                    maxWidth: "-webkit-fill-available",
                                  }}
                                >
                                  <Text className="font14Bold">
                                    Card:&nbsp;
                                    <span className="font14">
                                      {cartItem?.card_type === "all_bank_plans"
                                        ? "Other Bank"
                                        : "Alfalah"}
                                    </span>
                                  </Text>
                                </Space>
                              ) : null}

                              {selectedAddons?.map((addOn, key) => {
                                //
                                return (
                                  <Row
                                    key={key}
                                    className="cursorPointer"
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      background: "#f2f3f7",
                                      alignItems: "baseline",
                                      maxWidth: "-webkit-fill-available",
                                    }}
                                  >
                                    {addOn?.name ? (
                                      <Col flex="0 0 auto">
                                        {cartItem?.enableStitching ? (
                                          <Text className="font14Bold ES">
                                            {addOn?.name}:&nbsp;
                                          </Text>
                                        ) : (
                                          <Text className="font14Bold WES">
                                            {addOn?.name}:&nbsp;
                                          </Text>
                                        )}
                                      </Col>
                                    ) : null}

                                    <Col flex="1 0 auto">
                                      <Space className="cursorPointer flexBetween">
                                        {cartItem?.enableStitching ? (
                                          <Text className="ES">
                                            {addOn?.selected_option?.name}
                                          </Text>
                                        ) : (
                                          <Text className="WES">
                                            {addOn?.selected_option?.name}
                                          </Text>
                                        )}
                                        <Image
                                          width={10}
                                          src={ArrowDown}
                                          alt="ArrowDown"
                                        // preview={false}
                                        />
                                      </Space>
                                    </Col>
                                  </Row>
                                );
                              })}

                              <Col flex="1 1 auto">
                                <Text className="font16Bold">
                                  {defaultCurrency}
                                  &nbsp;
                                  {/* {cartItem?.price
                                    ? parseFloat(cartItem?.price).toFixed(2)
                                    : "N/A"} */}
                                  {cartItem?.price ? cartItem?.price : "N/A"}
                                </Text>
                              </Col>
                            </Space>
                          </Space>
                        </Col>
                      );
                    })}
                  </Col>

                  <Col span={24} className="flexCenter">
                    <DynamicButton
                      name="View Cart"
                      link={`/cart/checkout?id=${cartId}`}
                      function={() => {
                        dispatch(displayCart(false));
                        setIsOpen(false);
                      }}
                      style={{
                        height: "49px",
                        width: "370px",
                        fontWeight: 600,
                        // color: "#FF0D50",
                        fontSize: "16px",
                        borderRadius: "6px",
                        // margin: "30px auto",
                        // border: "1px solid #FF0D50",
                        margin: "10px auto 20px auto",
                      }}
                      className="primary_btn_outline"
                    />
                  </Col>

                  <Col span={24} className="marginTop10 flexCenter">
                    <AiFillCloseCircle
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      onClick={() => setIsOpen(false)}
                    />
                  </Col>
                </Row>
              ) : (
                <Col className="flexColumn flexCenter">
                  <Image
                    height={70}
                    width="auto"
                    // preview={false}
                    src={EmptyBag}
                    alt="Empty Cart"
                  />

                  <Text
                    style={{
                      marginTop: "10px",
                      opacity: 1,
                      fontWeight: 100,
                      fontSize: "20px",
                      color: "#000000",
                      letterSpacing: "0px",
                      fontFamily: "ZemestroStd-Medium",
                    }}
                  >
                    {`Hey! It's lonely here`}
                  </Text>
                  <Text
                    style={{
                      opacity: 1,
                      fontWeight: 100,
                      color: "#747474",
                      letterSpacing: "0px",
                      fontFamily: "ZemestroStd-Regular",
                    }}
                  >
                    Your cart is empty
                  </Text>

                  <DynamicButton
                    link="/"
                    name="Shop Now"
                    className="primary_btn_outline font18Bold"
                    style={{
                      width: "171px",
                      height: "41px",
                      margin: "30px auto 20px auto",
                    }}
                  />
                </Col>
              )}
            </Spin>
          </Col>
        )}
      </Col>
    </>
  );
}
