import { createSlice } from "@reduxjs/toolkit";

export const megaMenuSlice = createSlice({
  name: "megaMenuSlice",
  initialState: {
    showMegaMenu: false,
  },
  reducers: {
    setShowMegaMenu: (state, action) => {
      state.showMegaMenu = action.payload;
    },
  },
});

export const { setShowMegaMenu } = megaMenuSlice.actions;
export default megaMenuSlice.reducer;
