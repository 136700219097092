import React, { useEffect } from "react";

const CustomScrollbar = ({ mainColor, secondaryColor, targetClass, width }) => {
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .${targetClass}::-webkit-scrollbar {
        width: ${width ? width : "5px"};
      }

      .${targetClass}::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background-color: ${secondaryColor}; 
      }

      .${targetClass}::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${mainColor};
      }

      .${targetClass}::-webkit-scrollbar-thumb:hover {
        background-color: darken(${mainColor}, 10%);
      }
    `;

    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [mainColor, targetClass]);

  return null;
};

export default CustomScrollbar;
