"use client";

//? Library Imports ------------------------------------------------------------>
import { Spin } from "antd";
import Image from "next/image";
import LoaderImage from "../../../assets/images/Loader/Loader.svg";

//? Assets --------------------------------------------------------------------->
import "./Loader.scss";
import Cookies from "js-cookie";

export default function Loader(props) {
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const styleElement = document.createElement("style");

  styleElement.innerHTML = `
  .ant-spin .ant-spin-dot-item {
    background-color: ${mainColor} !important;
    color: ${mainColor} !important;
  }
`;

  document.head.appendChild(styleElement);
  return (
    <div className="spin">
      <Spin size={props?.size} />
    </div>
  );
}

// Ant design spinner with custom image
