"use client";
//? Library Imports ------------------------------------------------------------>
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Typography } from "antd";
import Cookies from "js-cookie";
import Link from "next/link";
import Image from "next/image";
//? Assets --------------------------------------------------------------------->
import "./Navigation.scss";
import logo from "../../../../assets/images/logos/nav-logo.svg";
import whiteLogo from "../../../../assets/images/alfamall white.png";
//? Modules -------------------------------------------------------------------->
import SearchBar from "../../SearchBar/SearchBar";
import SideNavDrawer from "./SideNavDrawer/SideNavDrawer";
import LoginPage from "@/components/SignUpPage/LoginPage";
import CartDropp from "@/components/CartPage/Modules/CartDropdown/CartDropp";
import WishlistDrop from "@/components/CheckoutPage/Modules/WishListModule/Modules/WishlistDropdown/WishlistDrop";
import LoginDropdown from "./LoginDropdown/LoginDropdown";
//? Redux Reducers ------------------------------------------------------------->
import AccountStatus from "@/functions/Cookies/Cookies";
import { usePathname } from "next/navigation";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

function Navigation(props) {
  let cartId = Cookies.get("cartId");
  let themeType = Cookies.get("user_type");
  const { loggedIn } = AccountStatus();
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);
  const [ShowMegaSearch, setShowMegaSearch] = useState(false);
  const [countryIdFromCookies, setCountryIdFromCookies] = useState(23);
  const showFullSearch = useSelector(
    (state) => state?.showComponentSlice?.showFullSearch
  );
  const pathname = usePathname();

  const cartCountTotalValue = useSelector((state) =>
    cartId ? state.cartSlice.cartCountTotal : 0
  );

  return (
    <Col className="header-inner">
      {!ShowMegaSearch && props?.data?.announcement_bar?.enable && (
        <div className="bg-primary textCenter padding10">
          <Text className="text-white font-400 font14">
            {props?.data?.announcement_bar?.announcement_text}{" "}
            <Link
              href={props?.data?.announcement_bar?.button_link || "#"}
              className="font14 font-600 text-white text-underline marginLeft5"
            >
              {" "}
              {props?.data?.announcement_bar?.button_text}
            </Link>
          </Text>
        </div>
      )}
      <Row
        align="middle"
        justify="center"
        className={
          showFullSearch && !pathname.includes("/fashion")
            ? "NavigationContainer flexCenter"
            : !showFullSearch
            ? "NavigationContainer flexBetween"
            : "NavigationContainerLanding flexBetween"
        }
        style={{
          backgroundColor: themeType === "premier" ? "#DEBD5C" : "#fff",
          width: themeType === "premier" ? "100% !important" : "99% !important",
        }}
      >
        {/* Logo always displayed and left-aligned */}
        <Col xl={4} lg={3} md={2}>
          <Row align="middle" justify="start" style={{ cursor: "pointer" }}>
            <Col
              xl={18}
              lg={18}
              style={{ padding: ShowMegaSearch ? "0px 0px 0px 20px" : "0px" }}
            >
              <Link href="/">
                <Image
                  className="marginTop10"
                  src={themeType === "premier" ? whiteLogo : logo}
                  height="auto"
                  width={170}
                  alt="Logo"
                />
              </Link>
            </Col>
          </Row>
        </Col>

        {/* Full or Mega Search Bar logic */}
        {ShowMegaSearch ? (
          <Col xl={12} lg={12} className="searchBar-container">
            {countryIdFromCookies && (
              <SearchBar
                userAgent={props?.userAgent}
                ShowMegaSearch={ShowMegaSearch}
                setShowMegaSearch={setShowMegaSearch}
                countryIdFromCookies={countryIdFromCookies}
              />
            )}
          </Col>
        ) : (
          <Col
            xl={12}
            lg={12}
            className="searchBar-container search-input-container"
          >
            {countryIdFromCookies && (
              <SearchBar
                userAgent={props?.userAgent}
                ShowMegaSearch={ShowMegaSearch}
                setShowMegaSearch={setShowMegaSearch}
                countryIdFromCookies={countryIdFromCookies}
              />
            )}
          </Col>
        )}

        {/* Right-aligned icons */}
        {!ShowMegaSearch ? (
          <Col xl={10} lg={10} className="icons category-dropdown">
            <Col>
              <WishlistDrop />
            </Col>
            <Col>
              <div style={{ position: "relative" }}>
                {cartCountTotalValue > 0 && (
                  <div className="cart-count-value">{cartCountTotalValue}</div>
                )}
                <CartDropp />
              </div>
            </Col>
            <Col>
              <LoginDropdown loggedIn={loggedIn} />
            </Col>
          </Col>
        ) : (
          <Col xl={5} lg={5}></Col>
        )}
      </Row>

      <LoginPage
        ShowLoginModal={ShowLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
      {showSideNav && (
        <SideNavDrawer
          data={props?.data?.navigation_bar?.side_navigation}
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
          userAgent={props?.userAgent}
        />
      )}
    </Col>
  );
}

export default Navigation;
