"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { Col, Typography, message } from "antd";
import { useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import { signOut } from "next-auth/react";

//? Assets --------------------------------------------------------------------->
import "./LoginDropdown.scss";
import { BiUser } from "react-icons/bi";
import { MdArrowForwardIos } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";

//? Modules -------------------------------------------------------------------->
import LoginPage from "@/components/SignUpPage/LoginPage";
import LoginIcon from "../../../../../assets/Icons/loginIcon.svg";
import MyProfile from "../../../../../assets/Icons/MyProfile.svg";

import logoutIcon from "../../../../../assets/Icons/logoutIcon.svg";
import paymentCard from "../../../../../assets/Icons/paymentCard.svg";
import UserIcon from "../../../../../assets/Icons/UserIcon.svg";
import UserIconMobile from "../../../../../assets/Icons/UserIconMobile.svg";
import OrderTracking from "../../../../../assets/Icons/OrderTracking.svg";
import PolicyIcon from "../../../../../assets/Icons/PolicyIcon.svg";
import MyOrdericon from "../../../../../assets/Icons/MyOrdericon.svg";
import TermsAndCondition from "../../../../../assets/Icons/TermsAndCondition.svg";

//? Redux Reducers ------------------------------------------------------------->
import {
  logoutReducer,
  changeActiveMenuReducer,
} from "@/redux/Slices/accountSlice";
import AccountStatus, { customerLogout } from "@/functions/Cookies/Cookies";
import Image from "next/image";
import TrackOrderModal from "@/components/Homepage/TrackOrderModal/TrackOrderModal";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function LoginDropdown({ isMobile }) {
  const dropdownRef = useRef(null);

  const {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();

  const customerName = useSelector(
    (state) => state?.accountSlice?.comverse_customer_name
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const [customerLoggedInn, setCustomerLoggedInn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [OpenOrderTrackingModal, setOpenOrderTrackingModal] = useState(false);


  //   const [Loading, setLoading] = useState(false);

  //? Functions ------------------------------------------------------------------>
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll", "overlay-container");
    } else {
      document.body.classList.remove("no-scroll", "overlay-container");
    }

    return () => {
      document.body.classList.remove("no-scroll", "overlay-container");
    };
  }, [isOpen]);
  const logOut = useCallback(() => {
    signOut({ redirect: false });
    customerLogout();
    dispatch(logoutReducer());
    router.push("/");
  }, [dispatch, router]);

  //? Life Cycles ---------------------------------------------------------------->

  useEffect(() => {
    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    if (dropdownRef.current) {
      dropdownRef.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  });

  const handleAlfaLogin = async () => {
    window.location.href = `${process.env.NEXT_PUBLIC_BACKEND}/payment/baf_sso`;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("dropdown-opened");
    } else {
      document.body.classList.remove("dropdown-opened");
    }
  }, [isOpen]);
  useEffect(() => {
    if (loggedIn === true) {
      setCustomerLoggedInn(true);
    } else setCustomerLoggedInn(false);
  }, [loggedIn]);

  return (
    <Col span={24} className="LoginDropdown">
      <Col
        onMouseOver={() => setSelectedIcon(4)}
        onMouseOut={() => setSelectedIcon(null)}
        onClick={() => setIsOpen(!isOpen)}
        className="flexColumn flexCenter cursorPointer user-login-icon"
      >
        <Text
          className={
            selectedIcon === 4
              ? "icon-container icon-container-active"
              : "icon-container"
          }
        >
          <Image
            style={{ marginBottom: isMobile ? "0px" : "3px" }}
            height={15}
            width={17}
            src={isMobile ? UserIconMobile : UserIcon}
            alt="user icon"
          />

          {/* <BiUser style={{ color: isOpen ? "#FF0D50" : "" }} /> */}
          {/* <span className="name" style={{ color: isOpen ? "#FF0D50" : "" }}>
            {customerLoggedInn ? (
              <span className="name" style={{ color: isOpen ? "#FF0D50" : "" }}>
                Hello
              </span>
            ) : (
              <span className="name" style={{ color: isOpen ? "#FF0D50" : "" }}>
                Login
              </span>
            )}
          </span> */}
        </Text>
      </Col>

      {/* {isOpen && <div className="overlay"></div>} */}
      {isOpen && (
        <Col
          ref={dropdownRef}
          className="LoginDropdownContent"
          // style={{
          //   borderBottomLeftRadius: "8px",
          //   borderBottomRightRadius: "8px",
          // }}
          onClick={() => setIsOpen(false)}
        >
          {/* <Col span={24}>
            {customerLoggedInn && customerName !== null ? (
              <Link href="/account" onClick={() => setIsOpen(false)}>
                <Text className="font18Bold">{"Hello " + customerName}</Text>
              </Link>
            ) : (
              <Text className="font18Bold">Hello Guest</Text>
            )}
          </Col> */}

          {!customerLoggedInn && (
            <Link href={"/login"}>
              <Col span={24} className="marginTop10">
                <div
                  onClick={() => {
                    // setShowLoginModal(true);
                    setIsOpen(false);
                  }}
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                    display: !customerLoggedInn ? "flex" : "none",
                  }}
                >
                  <Image
                    className="paddingRight5"
                    src={LoginIcon}
                    width={20}
                    height={15}
                  />
                  <Text
                    style={{
                      marginLeft: "5px",
                    }}
                    // onClick={() => handleAlfaLogin()}
                  >
                    Login
                  </Text>
                  {/* <MdArrowForwardIos style={{ color: "#FF0D50" }} /> */}
                </div>
              </Col>
            </Link>
          )}
          {loggedIn && (
            <Link
              href="/account"
              onClick={() => {
                setIsOpen(false);
                dispatch(changeActiveMenuReducer("dashboard"));
              }}
            >
              <Col span={24} className="marginTop10 ">
                <Image
                  style={{ opacity: "0.5" }}
                  src={MyProfile}
                  width={20}
                  height={15}
                />
                <Text
                  style={{
                    marginLeft: "10px",
                  }}
                  className="cursorPointer font14"
                >
                  My Profile
                </Text>
              </Col>
            </Link>
          )}

          {loggedIn && (
            <>
              <Link
                href="/account"
                // onClick={() => {
                //   dispatch(changeActiveMenuReducer("wishlist"));
                // }}
              >
                <Col span={24} className="marginTop10">
                  <Image src={MyOrdericon} width={20} height={15} />
                  <Text
                    style={{
                      marginLeft: "10px",
                    }}
                    className="cursorPointer font14"
                  >
                    My Orders
                  </Text>
                </Col>
              </Link>
            </>
          )}
          {/* <Col span={24} className="marginTop10">
            <a
              target="_blank"
              href="https://oms.alfamall.com/ordertracking/021024025744950817"
              style={{
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Image src={OrderTracking} width={15} height={15} />
              <Text
                style={{
                  marginLeft: "15px",
                }}
              >
                Track order
              </Text>
            </a>
          </Col> */}

          <Col span={24} className="marginTop10">
            <button
              style={{
                cursor: "pointer",
                display: "flex",
                border: "none",
                background: "none",
                padding: 0,
                margin: 0,
              }}
              onClick={() => setOpenOrderTrackingModal(true)}
            >
              <Image src={OrderTracking} width={15} height={15} />
              <Text
                style={{
                  marginLeft: "15px",
                }}
                className="font14"
              >
                Track order
              </Text>
            </button>
          </Col>

          <Link href="/pages/static/shipping-policy">
            <Col span={24} className="marginTop10">
              <Image
                style={{ opacity: "0.5" }}
                src={PolicyIcon}
                width={20}
                height={15}
              />
              <Text
                style={{
                  marginLeft: "10px",
                }}
                className="font14"
              >
                Shipping Policy
              </Text>
            </Col>
          </Link>
          <Link href="/pages/static/terms-and-conditions">
            <Col span={24} className="marginTop10">
              <Image
                style={{ opacity: "0.5" }}
                src={TermsAndCondition}
                width={20}
                height={15}
              />
              <Text
                style={{
                  marginLeft: "10px",
                }}
                className="font14"
              >
                Terms and Conditions
              </Text>
            </Col>
          </Link>
          {/* <Link href="#">
            <Col span={24} className=" marginTop10">
              <Image src={paymentCard} width={20} height={15} />
              <Text
                style={{
                  marginLeft: "10px",
                }}
                className="cursorPointer font14"
              >
                AlfaMall Payment Card
              </Text>
            </Col>
          </Link> */}
          {/* <Link href="#">
            <Col span={24} className="paddingBottom10">
              <Text className="cursorPointer font14">FAQs</Text>
            </Col>
          </Link>
          <Link href="/about-us">
            <Col span={24} className="paddingBottom10">
              <Text className="cursorPointer font14">About Us</Text>
            </Col>
          </Link>
          <Link href="/pages/static/terms-of-service">
            <Col span={24} className="paddingBottom10">
              <Text className="cursorPointer font14">Terms & Conditions</Text>
            </Col>
          </Link>
          <Link href="/pages/static/privacy-policy">
            <Col span={24} className="paddingBottom10">
              <Text className="cursorPointer font14">Privacy Policy</Text>
            </Col>
          </Link> */}

          {customerLoggedInn && (
            <Col span={24} className="marginTop10">
              <div
                key={1}
                onClick={() => {
                  message.success("Logged out successfully!");
                  logOut();
                  setShowLoginModal(false);
                  setIsOpen(false);
                }}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Image src={logoutIcon} width={20} height={15} />
                <Text
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Logout
                </Text>
              </div>
            </Col>
          )}

          {/* <Col span={24} className="marginTop10 flexCenter">
            <AiFillCloseCircle
              style={{ fontSize: "24px", cursor: "pointer" }}
              onClick={() => setIsOpen(!isOpen)}
            />
          </Col> */}
        </Col>
      )}

      <LoginPage
        ShowLoginModal={ShowLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
      {OpenOrderTrackingModal && (
        <TrackOrderModal
          showTrackOrderModal={setOpenOrderTrackingModal}
          openTrackOrderModal={OpenOrderTrackingModal}
        />
      )}
    </Col>
  );
}
