import { Modal} from "antd";
import { CloseOutlined } from "@ant-design/icons";

const TrackOrderModal = ({ openTrackOrderModal, showTrackOrderModal }) => {


  return (
    <Modal
      open={openTrackOrderModal}
      onCancel={() => showTrackOrderModal(false)}
      footer={null}
      centered
      closeIcon={<CloseOutlined style={{ color: "black" }} />}
      bodyStyle={{
        padding: 0,
      }}
      className="discount-card-modal"
    >
      {/* Embed the order tracking page inside an iframe */}
      <iframe
        src="https://oms.alfamall.com/ordertracking/021024025744950817"
        title="Order Tracking"
        style={{
          width: "100%",    
          height: "95vh",
          border: "none",   
        }}
      />
    </Modal>
  );
};

export default TrackOrderModal;
