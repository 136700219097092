"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Col,
  Row,
  Modal,
  Space,
  Skeleton,
  Typography,
  // Image,
} from "antd";
import axios from "axios";
import Link from "next/link";
import Cookies from "js-cookie";

// import Image from "next/image";
// import { useState } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";

//? Assets --------------------------------------------------------------------->
import "./MegaSearch.scss";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";

//? Modules -------------------------------------------------------------------->
// import Loader from "../Loader/Loader";
import ProductCard from "../ProductsCarousel/ProductCard/ProductCard";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MegaSearch({
  userAgent,
  ActivePage,
  SearchQuery,
  setActivePage,
  SearchResults,
  ShowMegaSearch,
  setSearchResults,
  setShowMegaSearch,
  SearchedProducts,
  setSearchedProducts,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const { isMobile, isTablet, isDesktop } = userAgent;
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  const defaultgeoLocationCountry = Cookies.get("geoLocation");
  const defaultCountryFromCookies = Cookies.get("defaultCountry");
  //? Redux States --------------------------------------------------------------->
  //? States --------------------------------------------------------------------->
  //? Functions ------------------------------------------------------------------>
  const searchMoreProducts = (SearchQuery) => {
    setActivePage(ActivePage + 1);
    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/search_products?page=${ActivePage}&q=` +
          SearchQuery +
          "&limit=12" +
          `&storefront=${defaultCurrencyIdFromCookies}`
      )
      .then((response) => {
        setSearchedProducts((prevProducts) => [
          ...prevProducts,
          ...response?.data.results,
        ]);
      })
      .catch(function (error) {
        console.log("Search Api Error", error);
      });
  };

  //? Life Cycles ---------------------------------------------------------------->

  return (
    <Col className="MegaSearch">
      <Modal
        centered
        footer={null}
        open={ShowMegaSearch}
        // className="MegaSearchModal"
        wrapClassName="MegaSearchModal"
        onCancel={() => setShowMegaSearch(false)}
      >
        <Row>
          <Col flex="20%" className="">
            <Col className="borderBottom padding15" span={24}>
              <Text className="font14Bold">Related Categories</Text>
            </Col>

            <Col className="padding15" span={24}>
              <Space direction="vertical" size="small">
                {SearchResults?.collection_aggs.map((category, index) => {
                  return (
                    <Link href={category.handle} key={index}>
                      <Space key={index} className="width100">
                        <SearchOutlined />
                        <Text className="font12">{category?.name}</Text>
                      </Space>
                    </Link>
                  );
                })}
              </Space>
            </Col>

            <Col className="borderBottom padding15" span={24}>
              <Text className="font14Bold">Trending</Text>
            </Col>

            <Col className="padding15" span={24}>
              <Space direction="vertical" size="small">
                {SearchResults?.collection_aggs.map((collection, index) => {
                  return (
                    <Link
                      key={index}
                      href={collection.handle}
                      // style={{ padding: "2px" }}
                    >
                      <Text className="font12">{collection?.name}</Text>
                    </Link>
                  );
                })}
              </Space>
            </Col>
          </Col>

          <Col
            flex="80%"
            className="borderLeft searchProducts positionRelative"
          >
            {SearchedProducts?.length ? (
              <Row gutter={[0, 12]} className="positionRelative row">
                <Col
                  span={24}
                  style={{ zIndex: 10, top: 10, width: "77%" }}
                  className="positionFixed backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                >
                  <Text className="font14Bold">Products</Text>
                </Col>
                <Col
                  span={24}
                  className="paddingLeft15 paddingRight15"
                  style={{ top: 60, overflow: "auto" }}
                  // style={{ height: 300, overflow: "auto" }}
                >
                  {/* <div style={{ height: "50vh", overflow: "auto" }}>
                    <InfiniteScroll
                      dataLength={SearchedProducts?.length} //This is important field to render the next data
                      next={() => searchMoreProducts()}
                      hasMore={true}
                      loader={<Loader />}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      <Row gutter={[10, 15]} className="positionRelative">
                        {SearchedProducts?.map((product, index) => {
                          return (
                            <Col span={4} key={index} className="padding0">
                              <Link
                                key={index}
                                href={product?.handle}
                                onClick={() => setShowMegaSearch(false)}
                              >
                                <ProductCard
                                  product={product}
                                  userAgent={userAgent}
                                />
                              </Link>
                            </Col>
                          );
                        })}
                      </Row>
                    </InfiniteScroll>
                  </div> */}

                  <Row gutter={[10, 15]} className="positionRelative">
                    {SearchedProducts?.map((product, index) => {
                      return (
                        <Col
                          span={4}
                          key={index}
                          className="padding0"
                          onClick={() => setShowMegaSearch(false)}
                        >
                          {/* <Link
                            href={product?.handle}
                            onClick={() => setShowMegaSearch(false)}
                          > */}
                          <ProductCard
                            product={product}
                            userAgent={userAgent}
                          />
                          {/* </Link> */}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row gutter={[0, 12]} className="positionRelative row">
                <Col
                  span={24}
                  style={{
                    zIndex: 10,
                    top: 10,
                    width: "77%",
                  }}
                  className="positionFixed backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                >
                  <Space
                    align="center"
                    style={{
                      background: "#F8D7DA",
                      padding: "5px",
                      width: "97%",
                      maxWidth: "97%",
                    }}
                  >
                    <ExclamationCircleOutlined
                      style={{
                        fontSize: "21px",
                        color: "#DC3545",
                      }}
                    />
                    <Text className="font14">Sorry, No Result Found</Text>
                  </Space>
                </Col>

                {SearchResults?.featured_collections?.length ? (
                  <>
                    <Col
                      span={24}
                      style={{ width: "77%", marginTop: "70px" }}
                      className="backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                    >
                      <Text className="font14Bold">Featured Collection</Text>
                    </Col>

                    <Col span={24} className="paddingLeft15 paddingRight15">
                      <Row gutter={[10, 15]} className="positionRelative">
                        {SearchResults?.featured_collections?.map(
                          (collection, index) => {
                            return (
                              <Col
                                key={index}
                                flex="14.2%"
                                className="padding0"
                              >
                                <Link
                                  href={collection?.link}
                                  onClick={() => setShowMegaSearch(false)}
                                >
                                  <Space direction="vertical" align="center">
                                    {/* <Image
                                      width={112}
                                      height={111}
                                      src={collection.img}
                                      alt={
                                        collection?.alt_text
                                          ? collection?.alt_text
                                          : "Collection"
                                      }
                                    /> */}
                                    <img
                                      width={112}
                                      height={111}
                                      src={collection.img}
                                      alt={
                                        collection?.alt_text
                                          ? collection?.alt_text
                                          : "Collection"
                                      }
                                    />
                                    <Text className="font18">
                                      {collection?.title
                                        ? collection?.title
                                        : "N/A"}
                                    </Text>
                                  </Space>
                                </Link>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </Col>
                  </>
                ) : null}

                {SearchResults?.catalogue_lists?.length ? (
                  <>
                    <Col
                      span={24}
                      style={{ width: "77%", marginTop: "0px" }}
                      className="backgroundLight borderBottom padding15 paddingLeft0 marginLeft15"
                    >
                      <Text className="font14Bold">New Catalog</Text>
                    </Col>

                    <Col span={24} className="paddingLeft15 paddingRight15">
                      <Row gutter={[10, 15]} className="positionRelative">
                        {SearchResults?.catalogue_lists?.map(
                          (catalogue, index) => {
                            return (
                              <Col span={4} className="padding0" key={index}>
                                <Link
                                  href={catalogue?.collection_handle}
                                  onClick={() => setShowMegaSearch(false)}
                                >
                                  {/* <Image
                                    width={163}
                                    height={180}
                                    alt="Catalogue"
                                    src={catalogue?.cdn_link}
                                  /> */}
                                  <img
                                    width={163}
                                    height={180}
                                    alt="Catalogue"
                                    src={catalogue?.cdn_link}
                                  />
                                </Link>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </Col>
                  </>
                ) : null}
              </Row>
            )}
          </Col>
        </Row>
      </Modal>
    </Col>
  );
}
